<template>
  <div>
    <nav
      class="navbar navbar-expand navbar-light fixed-top"
      style="max-height: 58px !important"
    >
      <div class="container">
        <a class="navbar-brand text-primary" href="/dashboard">
          <img class="img-fluid d-block" src="img/logo.png" width="190px" />
        </a>
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto text-secondary">
          <a
            class="sidebar-brand d-flex align-items-right justify-content-right"
            href="index.html"
          >
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
          </a>
          <li class="nav-item dropdown no-arrow" style="">
            <a
              class="
                sidebar-brand
                d-flex
                align-items-right
                justify-content-right
              "
              href="index.html"
            >
            </a>
          </li>
          <!-- Nav Item - Alerts -->
          <!-- Nav Item - User Information -->
          <!-- <li class="nav-item dropdown no-arrow text-secondary">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bars fa-fw text-secondary fa-lg"></i>
            </a>
            <div
              class="
                dropdown-menu dropdown-menu-right
                animated--grow-in
                shadow-sm
                py-5
                px-4
              "
              style="font-size: 18px; min-height: 200px"
              aria-labelledby="userDropdown"
            >
              <a class="dropdown-item text-secondary py-2" href="cadastre.html">
                Cadastre-se
              </a>
              <a class="dropdown-item text-secondary py-2" href="duvidas.html">
                Tire suas dúvidas
              </a>
              <a class="dropdown-item text-secondary py-2" href="sobre.html">
                Sobre nós
              </a>
            </div>
          </li> -->
        </ul>
      </div>
    </nav>
    <flash-message
      transitionIn="animated swing"
      class="alert-system"
    ></flash-message>
    <Login height="100%"></Login>

    <div class="footer1 bg-primary text-light sec6">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 p-4 px-5">
            <img class="img-fluid d-block" src="img/logo2.png" width="200px" />
            <!-- <p class="text-secondary mt-3">
              Informações da empresa<br />Endereço
            </p>
            <hr
              style="
                border-color: #fff;
                border-style: solid;
                border-top-width: 1px;
              "
            />
            <h5 class="text-secondary pt-4"><b>Siga as nossas redes sociais!</b></h5><br>
            <div
              class="
                col-md-12
                d-flex
                align-items-center
                justify-content-between
                
                text-light
              "
            >
              <a href="#" target="_blank">
                <i
                  class="d-block fa fa-facebook-official fa-lg mr-2 text-white"
                ></i>
              </a>
              <a href="#" target="_blank">
                <i class="d-block fa fa-instagram fa-lg mx-2 text-white"></i>
              </a>
              <a href="#" target="_blank">
                <i class="d-block fa fa-linkedin fa-lg mx-2 text-white"></i>
              </a>
              <a href="#" class="text-white"> @zerodivida </a>
            </div> 
            <div
              class="
                col-md-12
                d-flex
                align-items-center
                justify-content-between
                
                text-light
              "
            >
            <p>CNPJ: 40.432.544.0001-47</p>
            </div> -->
          </div>
          <div class="col-12 col-lg-5 p-4 px-5">
            <h5 class="text-secondary"><b>Sobre nós!</b></h5>
            <p class="">
              O Zero Dívida é um portal de autonegociação em que você pode
              quitar suas dívidas, de diferentes marcas, num só lugar.<br><br> Nosso
              portal foi criado em parceria com a PGMais, empresa referência no
              mercado de tecnologia em cobrança desde 2010.<br><br> Nossa busca é
              facilitar a vida das pessoas que estão endividadas no Brasil.<br>
              <b>Aqui, a praticidade é a palavra-chave!</b>
            </p>
            <a class="btn btn-lg btn-link text-white p-0" href="mailto:contato@zerodivida.com.br?subject=Duvidas Portal Zero Dividas Online"
              >Fale conosco&nbsp;<i class="fa fa-arrow-right fa-fw"></i
            ></a>
          </div>
          <div 
          v-bind:class="[this.largura < 500 ? 'col-12 col-lg-3 p-4 px-5' : 'col-12 col-lg-3 p-4']"
          >
            <h5 class="text-secondary">
              <b>São diferentes marcas, parceiras, que estão no Zero Dívida</b>
            </h5>
            <!-- <hr
              style="
                border-color: #fff;
                border-style: solid;
                border-top-width: 1px;
              "
            />
            <a href="#" class="text-white"> Política de privacidade de dados </a
            ><br /><a href="#" class="text-white"> Termos de uso </a><br /><a
              href="#"
              class="text-white"
            >
              Política de cookies
            </a> -->
          </div>
        </div>
      </div>
      <div class="nav"></div>
    </div>
    <!-- <div class="nav">
      <a class="nav-link wts bg-success" @click="openWhats()" target="_blank" style="cursor:pointer">
        <i class="fa fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Login from "@/modules/auth/Login";
export default {
  name: "Home",
  components: {
    Login,
  },
  data: () => ({
    hrefwhatsapp: "https://api.whatsapp.com/send?l=pt&phone=551132303765",
    links: [
      { icon: "", text: "Quem Somos", route: "somos" },
      { icon: "", text: "Experimente", route: "experimente" },
      {
        icon: "",
        text: "Termos de uso",
        route: "termos",
        params: "Termos de uso",
      },
      {
        icon: "",
        text: "Política de privacidade",
        route: "sobre",
        params: "Política de privacidade",
      },
      {
        icon: "",
        text: "Política de cookies",
        route: "sobre",
        params: "Política de cookies",
      },
    ],
    largura: null,
  }),
  methods: {
    ...mapActions("meusBoletos", ["ActionSendAccessLogWithoutToken"]),
    async openWhats() {
      if (this.user != null) {
        var whats = await this.ActionGetWhatsText();
        this.hrefwhatsapp = this.hrefwhatsapp + "&text=" + whats[0];
      } else {
        console.log("registrando log...");
        await this.ActionSendAccessLogWithoutToken({
          ValueId: 16,
        });
        this.hrefwhatsapp =
          this.hrefwhatsapp +
          "&text=Olá, não encontrei meus dados podem me ajudar ?";

        var document = window.document.getElementsByName("docnumber")[0].value;

        if (document != "" && document != null)
          this.hrefwhatsapp =
            this.hrefwhatsapp + " Meu documento é : " + document;

        console.log("log registrado.");
      }

      window.open(this.hrefwhatsapp, "_blank");
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  created() {
    this.largura = window.screen.width;
  },
};
</script>
<style>
</style>
