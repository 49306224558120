<template>
  <div class="container" style="margin-bottom: 30px;">
    <div class="row" style="padding: 20px">
      <div
        class="
          mx-auto
          bg-white
          rounded
          p-5
          lpp
          col-6 col-md-6
          d-none d-sm-block
        "
        style="
          background-image: url('img/hero2.jpg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 550px;
        "
      ></div>
      <div class="col-lg-5 px-3 col-md-8" style="">
        <div class="card mx-auto p-1">
          <div class="card-body">
            <h3 class="text-center" style="">Identidade Verificada</h3>
            <img class="img-fluid d-block mx-auto py-2" src="/img/shield.png" />
            <p>
              Confirmarmos seus dados e sua identidade foi verificada. Agora
              você está pronto(a) para começar a negociar suas pendências no
              portal. Seja bem vindo(a).
            </p>
            <div class="col-12 text-center">
            <a
              @click="inicio()"
              class="btn btn-secondary btn-lg home-form mx-2
                  my-2
                  quicks
                  py-3
                  px-3"
              href="#"
              >Começar a usar</a
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Verificado",
  data: () => ({}),
  created: function () {},
  computed: {},
  methods: {
    inicio() {
      location.href = "/";
    },
  },
};
</script>