<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogModal"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Como gostaria de receber o Código de Acesso?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form >

                  <ProgressLoading v-bind:loading="loading" @setLoading="loading = $event"/>

                  <AlertMessage v-bind:error="error"/>

                  <v-radio-group
                      v-model="EmailOrPhone"
                      row
                  >
                      <v-radio
                          label="E-mail"
                          value="email"
                      ></v-radio>
                      <v-radio
                          label="Telefone"
                          value="telefone"
                      ></v-radio>
                  </v-radio-group>

                  <v-col
                      class="d-flex"
                      cols="12"
                      sm="10"
                      v-if="EmailOrPhone === 'email'"
                  >
                      <v-text-field
                          v-model="form.email"
                          :error-messages="emailErrors"
                          label="E-mail"
                          required
                          @input="$v.form.email.$touch()"
                          @blur="$v.form.email.$touch()"
                      >
                      <!-- CPF -->
                      </v-text-field>
                  </v-col>

                  <v-col
                      class="d-flex"
                      cols="12"
                      sm="10"
                      v-if="EmailOrPhone === 'telefone'"
                  >
                      <v-text-field
                          v-model="form.telefone"
                          :error-messages="telefoneErrors"
                          label="Telefone"
                          required
                          @input="$v.form.telefone.$touch()"
                          @blur="$v.form.telefone.$touch()"
                          v-mask="['(##) # ####-####']"
                      >
                      <!-- CPF -->
                      </v-text-field>
                  </v-col>
                </form>
              </v-col>
            </v-row>
          </v-container>
          <small>*itens obrigatório</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="exitDialog"
          >
            Voltar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="sendNewContact"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import ProgressLoading from './ProgressLoading.vue';
import AlertMessage from './AlertMessage.vue';

export default {
    name: "FormEmailOrPhone",
    components: {
      ProgressLoading,
      AlertMessage
    },
    props: {
        dialogModal: {
            type: Boolean
        }
    },
    data() {
      return {
        EmailOrPhone: "email",
        email: {
            items: []
        },
        telefone: {
            items: []
        },
        loading: false,
        form: {
          email: null
        },
        error: {
            alert: false,
            status: null,
            message: null
        }
      }
    },
    mixins: [validationMixin],
    directives: {mask},
    validations: {
        form: {
            email: {required, email},
            telefone: {required, minLength: minLength(16)},
        }
    },
    computed: {
        ...mapState('auth', ['contacts']),
        emailErrors () {
            const errors = [];
            if(!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push('E-mail é obrigatório!');
            !this.$v.form.email.email && errors.push('Digite um e-mail válido!');

            return errors;
        },
        telefoneErrors () {
            const errors = [];
            if(!this.$v.form.telefone.$dirty) return errors;
            !this.$v.form.telefone.required && errors.push('Telefone é obrigatório!');
            !this.$v.form.telefone.minLength && errors.push('Digite um Telefone válido');

            return errors;
        }
    },
    methods: {
      ...mapActions("auth", ["ActionSendPromoCode", "ActionSetCurrentForm", "ActionCreateDebtor", "ActionSetContacts"]),

      exitDialog() {
        this.$emit("setDialog", false);
      },

      async sendNewContact() {
        this.$v.$touch();
        this.loading = true;

          // envio por email 
          if (this.emailErrors.length !== 0) {
              // formulário inválido
              this.loading = false;
          } else {

            if (this.contacts.SourceInfo === "API_EXTERNA") {
                try {
                  const response = await this.ActionCreateDebtor({
                      DocNumber: this.contacts.DocNumber,
                      Email: this.form.email
                  });

                  if (response.status === 200) {
                      // passa pra tela de promocode

                      this.ActionSetContacts({
                          DebtorID: response.data.results.DebtorID,
                      });

                      this.ActionSetCurrentForm('form-promo-code');
                      this.loading = false;
                  } else {
                      this.loading = false;
                      console.log("tratar o erro");
                      // algum status de erro
                  }
                } catch (error) {
                    // algum tratamento de erro
                    console.log(error)
                }
            }

            if (this.EmailOrPhone == "email") {
              
              await this.ActionSendPromoCode({
                  DebtorId: this.contacts.DebtorID,
                  Email: this.form.email
              });

              this.loading = false;
              this.ActionSetCurrentForm('form-promo-code');
              this.loading = false;
            }
          }  

          // envio por telefone sms
          if (this.telefoneErrors.length !== 0) {
              // formulário inválido
              this.loading = false;
          } else {

            if(this.EmailOrPhone == "telefone") {
              //console.log("Rotina enviar promocode de novo contato no sms")
              this.loading = false;
            }
          }  
      }
    },
}
</script>