<template>
    <div>
        <v-alert
            v-model="error.alert"
            dismissible
            color="warning"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-alert"
            v-if="error.status === 'ERROR'"
            transition="slide-x-transition"
        >
            {{ error.message }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "AlertMessage",
    props: {
        error: {
                type: Object
        }
    }
}
</script>