import aesjs from 'aes-js';
import CryptoJS from 'crypto-js';

export const encryptTextWithKey = (text, key) => {
  if (!text || !key) return false;

  var textAsBytes = aesjs.utils.utf8.toBytes(text);
  var aes = new aesjs.AES(getEncryptedKeyBuffer(key));
  var encryptedBytes = aes.encrypt(aesjs.padding.pkcs7.pad(textAsBytes));

  return aesjs.utils.hex.fromBytes(encryptedBytes);
}

export const getEncryptedKeyBuffer = (key) => {
  var key256 = aesjs.utils.hex.toBytes(CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex));
  var bufferArray = [];

  for (var i = 0; i < key256.length; i++) {
    bufferArray.push(key256[i]);
  }

  return bufferArray.slice(0, 16);
}

export const decryptHashWithKey = (hash, key) => {
  if (!hash || !key) return false;
  var encryptedBytes = aesjs.utils.hex.toBytes(hash);
  var aes = new aesjs.AES(getEncryptedKeyBuffer(key));
  var decryptedBytes = aes.decrypt(encryptedBytes);
  return aesjs.utils.utf8.fromBytes(decryptedBytes);
}
