<template>
<div class="container" style=" margin-bottom: 30px;">
      <div class="row" style="padding: 20px;">
        <div
          class="
            mx-auto
            bg-white
            rounded
            p-5
            lpp
            col-6 col-md-6
            d-none d-sm-block
          "
          style="
            background-image: url('img/hero2.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            max-width: 500px !important;
          "
          :style="this.largura < 500 ? '' : ''"

        ></div>
    <div class="col-md-5 my-2 col-12">
        <div class="card mx-auto p-1 lpp" :style="this.largura < 500 ? 'margin-top: -15px; min-height: auto !important' : 'margin-top: -15px; '">
            <div class="card-body" :style="this.largura < 500 ? 'padding: 17px;' : 'padding: 30px'" style="">
                <h4 class="" style="">Código de acesso</h4>
                <p>Agora insira o código que você recebeu para que possamos verificar sua identidade.</p>
                <form v-on:submit="login" v-on:submit.prevent >
                <div class="form-group mb-3 ">
                    <p class="text-left small mb-1 text-dark" style=""> Digite o código no campo abaixo:</p> 
                    <input type="password" class="form-control form-control-lg
                  text-center
                  home-form
                  quicks" placeholder="Código de acesso"
                    v-model="form.promoCode"
                    :error-messages="promoCodeErrors"
                    :disabled="loading"
                    @input="$v.form.promoCode.$touch()"
                    @blur="$v.form.promoCode.$touch()"> 
                    <a @click="sendCodeForm"  
                    class="btn btn-sm btn-link text-center justify-content-center align-items-center d-flex"
                     href="#" style="text-decoration: none;">Não tenho um código de acesso</a>
                </div>
                <button type="submit" 
                    class="float-right btn btn-primary btn-lg home-form mx-2 my-2 quicks py-3 px-3 text-white" 
                    v-if="!loading && this.largura > 500">Confirmar identidade</button>
                <button vif type="submit" 
                    class="float-right btn btn-primary btn-lg home-form mx-2 my-2 quicks py-3 px-4 text-white" 
                    v-if="!loading && this.largura < 500">Confirmar</button>
                </form>
                <button  @click="voltar" type="button" class="float-left
                  btn btn-secondary btn-lg
                  home-form
                  mx-2
                  my-2
                  quicks
                  py-3
                  px-4
                  text-white" v-if="!loading && this.largura > 500">Voltar</button>
                  <button  @click="voltar" type="button" class="float-left
                  btn btn-secondary btn-lg
                  home-form
                  mx-2
                  my-2
                  quicks
                  py-3
                  px-5
                  text-white" v-if="!loading && this.largura < 500">Voltar</button>
                  <ProgressLoading v-bind:loading="loading"/>
            </div>
        </div>
    </div>
    </div>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ProgressLoading from './ProgressLoading.vue';

export default {
    components: {
        ProgressLoading
    },
    mixins: [validationMixin],
    directives: {mask},
    validations: {
        form: {
            promoCode: {required, minLength: minLength(5)},
        }
    },
    data: () => ({
        form: {
            promoCode: null
        },
        error: {
            alert: false,
            status: null,
            message: null
        },
        loading: false,
        dialog: false,
        largura: null
    }),
    async created() {
        this.largura = window.screen.width;
    },
    computed: {
        ...mapState('auth', ['contacts']),

        classMessage: function () {
            return {
                'text-danger': this.error.status == "ERROR",  
                'text-muted': !this.error.status == "ERROR"
            }
        },

        promoCodeErrors () {
            const errors = [];

            if(!this.$v.form.promoCode.$dirty) return errors;
            !this.$v.form.promoCode.required && errors.push('Código é obrigatório!');
            !this.$v.form.promoCode.minLength && errors.push('O Código não está completo!');

            return errors;
        },
    },
    methods: {
        ...mapActions('auth', ['ActionDoLogin', 'ActionSetCurrentForm','ActionGetIPAddress']),

        async login() {
            this.promoCodeErrors.pop();
            this.$v.$touch();

            if (this.$v.$invalid) {
                // formulário inválido
            } else {
                this.loading = true;
                let debtorId = this.contacts.DebtorID;
                let promoCode = this.form.promoCode;
                var ipmachine = await this.ActionGetIPAddress();

                try {
                    await this.ActionDoLogin({
                        DebtorID: debtorId,
                        PromoCode: promoCode,
                        device_name: process.env.VUE_APP_API_DEVICE,
                        IP:ipmachine,
                        OS:navigator.platform
                    })
                    this.loading =  false;
                    this.ActionSetCurrentForm('verificado');
                    //this.$router.push({ path: 'dashboard',foce:true });
                    
                   // this.$router.push({ name: 'dashboard' });
                } catch (error) {
                    this.flashMessage.show({
                        timeout:300,
                        status: 'error',
                        position:'top right', 
                        title: 'Erro', 
                        message: 'ocorreu um erro ao autenticar, promocode incorreto ou inválido'});
                    this.loading = false;
                    return;
                }
            }
        },
        sendCodeForm() {
            this.ActionSetCurrentForm('form-send-code');
        },
        voltar() {
            this.ActionSetCurrentForm("form-doc-number");
        }
    },
}
</script>
<style scoped>

.col-12.col-md-12 {

  margin-top: 100px !important;

}

</style>