<template>
  <div id="inside" style="min-height: 100vh; background-color: #f5f6fa !important;">
    <div class="container" v-if="!loading">
      <div class="row">
        <nav class="navbar navbar-expand navbar-light fixed-top" >
    <div class="container"> 
      <router-link to="/dashboard" class="nav-item" style="color: #0f6f9b; text-decoration: none;"><a class="navbar-brand text-primary">
        <img class="img-fluid d-block" src="/img/logo.png" width="160px">
      </a></router-link>
      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto text-secondary"><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
          <!-- Nav Item - Search Dropdown (Visible Only XS) -->
        </a>
        <li class="nav-item dropdown no-arrow" style=""><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
          </a>
        </li>
        <!-- Nav Item - Alerts -->
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow text-secondary" v-if="fullName !== null">
          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 small text-secondary"> {{ fullName.split(" ")[0] }} </span>
            <i class="fa fa-user-o fa-fw text-secondary"></i>
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <router-link to="/dashboard/cadastro" class="nav-item"> <a class="dropdown-item text-secondary">
              <i class="fa fa-user-o fa-sm fa-fw mr-2"></i> Seu cadastro </a> </router-link>
            <router-link to="/dashboard/cadastro" class="nav-item"> <a class="dropdown-item text-secondary" href="dashboard/cadastro">
              <i class="fa fa-key fa-sm fa-fw mr-2"></i> Alterar senha </a> </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-secondary" href="#" data-toggle="modal" data-target="#logout">
              <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
    
  </div>
  </div>
  <nav class="navbar navbar-expand flex-row fixed-bottom d-flex justify-content-around navbar-dark bg-primary py-1">
    <div class="flex-row d-flex justify-content-around mx-auto" id="navbar3">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item mx-100p text-center"> 
          <a class="nav-link" href="/dashboard"><i class="fa fa-fw fa-home text-white fa-lg"></i>
            <p class="small text-white mb-0">Home</p>
          </a>
        </li>
        <li class="nav-item mx-100p text-center">
          <button @click="contractsPending()" class="nav-link"><i class="fa fa-fw fa-edit text-white fa-lg"></i>
            <p class="small text-white mb-0">Contratos</p>
          </button> </li>
        <li class="nav-item mx-100p text-center"> 
          <button @click="verBoletos()" class="nav-link"><i class="fa fa-fw fa-file-text-o text-white fa-lg"></i><br>
            <p class="small text-white mb-0">Meus Acordos</p>
          </button> </li>
      </ul>
    </div>
  </nav>
  <ProgressLoading v-bind:loading="loading" />
  </div>
</template>

<script>
import ProgressLoading from "./ProgressLoading.vue";
import { mapActions } from "vuex";


export default {
  name: "fake-loading",
  components: {
    ProgressLoading,
  },
  data() {
    return {
      loadDividas: false,
      loading: false,
      largura: null,
      accessdoc: null,
      promoCode: null,
      accessdocFormated: null,
      userIdAdm: null,
      fullName: null,
      ipUser: null
    };
  },
  async created() {
    this.largura = window.screen.width;
    this.loading = true;
    const infoEncrypted = localStorage.getItem("infoEncrypted");
    const encryptedData = localStorage.getItem("encryptedData");

    if (!infoEncrypted && !encryptedData) {
      this.flashMessage.show({
        timeout: 300,
        status: "error",
        position: "top right",
        title: "Erro",
        message: "Não foi possível acessar informações do devedor.",
      });
      return;
    }

    if (infoEncrypted) {
      this.verifyIfIsAccessLinkAdmAsDebtor(infoEncrypted);
    }
  },
  methods: {
    ...mapActions("auth", [
            "ActionGetLinkAccessAsDebtor",
            "ActionGetContactsByDocNumber",
            "ActionDoLogin",
            "ActionSetCurrentForm"
          ]),


    async verifyIfIsAccessLinkAdmAsDebtor(infoEncrypted) {
        this.loading = true;

        var result = await this.ActionGetLinkAccessAsDebtor({ infoEncrypted: infoEncrypted, });
        console.log('resultYYYYYY: ' + JSON.stringify(result));

        if(!result[0].Message) {
          try {
              
              var data = result[0];
              this.accessdoc = data.DocNumber;
              this.promoCode = data.PromoCode;
              this.fullName = data.FullName;
              this.accessdocFormated = this.formatarCnpjCpf(this.accessdoc);
              const userAdm = {
                UserID: data.UserID,
                UserFullName: data.UserFullName
              }

              localStorage.setItem("userAdm", JSON.stringify(userAdm));
              console.log('userAdm: ' + JSON.stringify(userAdm));




            console.log('accessdoc: ' + JSON.stringify(this.accessdoc));
            console.log('promoCode: ' + JSON.stringify(this.promoCode));
            console.log('this.fullName: ' + JSON.stringify(this.fullName));
            console.log('this.accessdocFormated: ' + JSON.stringify(this.accessdocFormated));

            if(this.accessdoc && this.promoCode) {
              this.login(this.accessdoc, this.promoCode, userAdm.UserID, userAdm.UserFullName);
            }
          } catch (error) {
            console.log('error: ' + JSON.stringify(error));
            this.loading = false;
          }
        } else {
          this.flashMessage.show({
              timeout:300,
              status: 'error',
              position:'top right', 
              title: 'Erro', 
              message: result[0].Message});
          this.loading = false;
          this.ActionSetCurrentForm("form-doc-number");
        }
    },

    async login(accessdoc, promocode, userId, userFullName) {

            let response = await this.ActionGetContactsByDocNumber({
                DocNumber: accessdoc,
                OS: navigator.platform,
                UserID: userId,
                UserFullName: userFullName,
                IP: this.ipUser ? this.ipUser : '',
              });

           if (response.data.code === 404) {
                this.flashMessage.show({
                    timeout: 1000,
                    status: "error",
                    title: "Ocorreu um erro",
                    message: response.data.message,
                });
                this.loading = false;
                return;
            } else {
                this.loading = true;
                let debtorId = response.data.results.DebtorID;
                let promoCode = promocode;

                try {
                    await this.ActionDoLogin({
                        DebtorID: debtorId,
                        PromoCode: promoCode,
                        device_name: process.env.VUE_APP_API_DEVICE,
                        IP: this.ipUser ? this.ipUser : '',
                        OS:navigator.platform,
                        UserID: userId,
                        UserFullName: userFullName
                    })
                    this.loading =  false;

                    this.$router.push({ name: 'dashboard' });
                } catch (error) {
                    this.flashMessage.show({
                    timeout: 1000,
                    status: "error",
                    title: "Ocorreu um erro",
                    message: 'Erro ao autenticar, promocode incorreto ou inválido',
                });
                this.loading = false;
                } finally {
                  this.loading = false;
                }
            }
        },

    formatarCnpjCpf(cnpjCpf) {
      // Limpe qualquer caractere não numérico
        const numeros = cnpjCpf.replace(/\D/g, '');

        // Verifique se é CNPJ ou CPF
        if (numeros.length === 11) {
            // CPF
            return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (numeros.length === 14) {
            // CNPJ
            return numeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else {
            // Se não for nem CPF nem CNPJ, retorne o valor original
            return cnpjCpf;
      }
    },

  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style>
.navbar {
  padding-bottom: 14px;
  padding-top: 15px;
  background-color: #fff;
  color: #333;
}

.footer1 {
  display: none !important;
}
</style>
