<template>
    <div>
        <FormEmailOrPhone v-bind:dialogModal="dialog" @setDialog="dialog = $event"/>
    <form
        class="px-5"
    >
        <ProgressLoading v-bind:loading="loading" class="mt-3"/>

        <AlertMessage v-bind:error="error" class="mt-5"/>

        <v-radio-group
            v-model="EmailOrPhone"
            row
        >
            <v-radio
                label="E-mail"
                value="email"
            ></v-radio>
            <v-radio
                label="Telefone"
                value="telefone"
            ></v-radio>
        </v-radio-group>

        <v-col
            class="d-flex"
            cols="12"
            sm="10"
            v-if="EmailOrPhone === 'email'"
        >
            <v-select
                v-if="email.items.length != 0"
                v-model="email.value"
                :items="email.items"
                label="E-mail"
                outlined
            ></v-select>
        </v-col>

        <v-col
            class="d-flex"
            cols="12"
            sm="10"
            v-if="EmailOrPhone === 'telefone'"
        >
            <v-select
                v-if="telefone.items.length != 0"
                v-model="telefone.value"
                :items="telefone.items"
                label="Telefone"
                outlined
            ></v-select>
        </v-col>

        <v-card-actions class="justify-center">
            <v-btn
                @click="voltar"
                color="blue-grey darken-1 white--text"
            >
                VOLTAR
            </v-btn>

            <v-btn
                class="mr-4"
                color="success"
                @click="sendPromoCodeOrCreateDebtor"
            >
                ENVIAR
            </v-btn>
        </v-card-actions>

        <div class="d-flex justify-end mt-3">
            <!-- <template v-slot:activator="{ on, attrs }"> -->
            <v-btn
                small
                text
                color="blue darken-4"
                @click="dialog = true"
            >
                Não encotrei meu E-mai/Telefone!
            </v-btn>
            <!-- </template> -->
        </div>
    </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormEmailOrPhone from './FormEmailOrPhone.vue';
import ProgressLoading from './ProgressLoading.vue';
import AlertMessage from './AlertMessage.vue';

export default {
    components: {
        FormEmailOrPhone,
        ProgressLoading,
        AlertMessage
    },
    data: () => ({
        EmailOrPhone: "email",
        email: {
            items: [],
            value: null
        },
        telefone: {
            items: [],
            value: null
        },
        alert: false,
        errorMessage: null,
        loading: false,
        dialog: false,
        error: {
            alert: false,
            status: null,
            message: null
        }
    }),
    computed: {
        ...mapState('auth', ['contacts']),
    },
    methods: {
        ...mapActions("auth", ["ActionCreateDebtor", "ActionSetCurrentForm", "ActionSendPromoCode", "ActionSetContacts"]),

        async sendPromoCodeOrCreateDebtor() {
            this.loading = true;

            if (this.EmailOrPhone == "email") {
                if(!this.email.value) {
                    this.error.alert = true;
                    this.error.message = "Você precisa selecionar um E-mail";
                    this.error.status = 'ERROR';
                    this.loading = false;
                    return;
                }

                if(this.contacts.SourceInfo == "API_EXTERNA") {
                    try {
                        const response = await this.ActionCreateDebtor({
                            DocNumber: this.contacts.DocNumber,
                            Email: this.email.value
                        });

                        if (response.status === 200) {
                            // passa pra tela de promocode

                            this.ActionSetContacts({
                                DebtorID: response.data.results.DebtorID,
                            });

                            this.ActionSetCurrentForm('form-promo-code');
                            this.loading = false;
                        } else {
                            // algum status de erro
                            this.loading = false;
                        }
                    } catch (error) {
                        // algum tratamento de erro
                        console.log(error)
                    }
                    
                } else {
                    
                    await this.ActionSendPromoCode({
                            DebtorId: this.contacts.DebtorID,
                            Email: this.email.value
                    });

                    this.loading = false;
                    this.ActionSetCurrentForm('form-promo-code');
                }
            } else if (this.EmailOrPhone == "telefone") {
                if(!this.telefone.value) {
                    this.error.alert = true;
                    this.error.message = "Você precisa selecionar um Telefone";
                    this.error.status = 'ERROR';
                    this.loading = false;
                    return;
                }

                if(this.contacts.SourceInfo == "API_EXTERNA") {
                    console.log("cria novo usuário e envia promocode por sms")
                    this.loading = false;
                } else {
                    console.log("envia promocode existente por sms")
                    this.loading = false;
                }
            }
        },
        voltar() {
            this.ActionSetCurrentForm('form-doc-number');
        },
    },
    async created() {
        if (this.email.items.length == 0 || this.telefone.length == 0) {
            this.email.items = await [this.contacts.Email];
            this.telefone.items = await [this.contacts.PhoneNumber];
        }
    },
}
</script>