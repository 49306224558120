<template>
  <body>
    <div class="container-fluid">
      <div class="row" style="margin-top: -65px">
        <div
          class="bg-white rounded lpp2 col-12 col-lg-8 mx-auto"
          style="
            background-image: linear-gradient(to bottom, #46c3f3, #6dcff6);
            background-position: top left;
            background-size: 100%;
            background-repeat: repeat;
            padding: 30px;
          "
          :style="
            this.largura < 500
              ? 'background-image: linear-gradient(to bottom, #46c3f3, #6dcff6);background-position: top left;background-size: 100%; background-repeat: repeat;padding: 30px;'
              : 'background-image: linear-gradient(to bottom, #46c3f3, #6dcff6);background-position: top left;background-size: 100%;background-repeat: repeat;padding-left: 100px;'
          "
        >
          <h3 class="display-4 text-primary mt-5">Zero Dívida Online:<br /></h3>
          <h3 class="mb-4 mt-3 text-light">
            Negocie diferentes dívidas num único lugar!<br />
          </h3>
          <h4 class="text-primary">
            Um portal prático e ágil para te ajudar.<br />
          </h4>
          <hr
            class="my-4"
            style="
              border-color: #fff;
              border-style: solid;
              border-top-width: 1px;
            "
          />
          <h4 class="my-5 text-light">
            <i class="fa fa-arrow-right text-primary"></i> Verifique as ofertas
            disponíveis para você:<br />
          </h4>
          <form v-on:submit="getContacts" v-on:submit.prevent>
            <div class="row">
              <div class="col-md-6 my-2 col-12">
                <input
                  type="tel"
                  required
                  class="form-control form-control-lg text-center home-form quicks"
                  placeholder="Digite aqui seu CPF/CNPJ"
                  id="form9"
                  style="height: 67px"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  v-model="form.accessdoc"
                />
              </div>
              <div class="text-center col-12 col-md-5" v-if="!loading">
                <button
                  type="submit"
                  v-bind:class="[
                    this.largura < 500
                      ? 'float-center btn btn-primary btn-lg home-form mx-2 my-2 quicks py-4 px-4 text-white'
                      : 'float-right btn btn-primary btn-lg home-form mx-2 my-2 quicks py-4 px-4 text-white',
                  ]"
                >
                  Consulte agora, é grátis&nbsp;<i
                    class="fa fa-arrow-right text-light"
                  ></i>
                </button>
              </div>
            </div>
          </form>
          <ProgressLoading v-bind:loading="loading" />
        </div>
        <div
          class="mx-auto bg-white rounded p-5 lpp col-4 col-md-4 d-none d-sm-block"
          style="
            background-image: url('img/hero2.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 550px;
          "
        ></div>
      </div>
    </div>
    <div class="text-center sec6">
      <div class="container">
        <div class="row mb-5">
          <div class="text-left col-12 col-lg-8" style="">
            <h1 class="my-5 text-primary">
              Vantagens de renegociar suas dívidas com a gente
              <!-- <spam style="color:#09b0f4;">Zero Dívidas</spam> -->
            </h1>
          </div>
          <div
            class="text-center col-lg-4 px-5 col-12 justify-content-center align-items-center d-flex"
          >
            <img
              class="img-fluid d-block mx-auto"
              src="img/simbolos11.png"
              style=""
              width="230px"
            />
          </div>
        </div>
        <div class="row p-3">
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">
                  Verifique as ofertas disponíveis para você
                </h4>
                <p>
                  Negocie suas dívidas (cartão, lojas, telefonia...) com nossas
                  marcas parceiras em nosso portal
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">
                  Seus dados estão seguros com a gente
                </h4>
                <p>
                  O Zero Dívida é parceiro da PGMais, empresa referência no
                  mercado de tecnologia em relacionamento há 12 anos
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">Negocie onde e quando quiser</h4>
                <p>
                  Autoatendimento disponível 24h! Acesse por smartphone ou
                  computador
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">100% online</h4>
                <p>
                  É simples, rápido e você não precisa falar ao telefone.
                  Renegocie em poucos cliques
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">Sem burocracia</h4>
                <p>
                  Deixe essa parte com a gente! Você só precisa escolher a opção
                  que cabe no seu bolso
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-1 col-12">
            <div class="card2" style="">
              <div class="card-body p-4">
                <img class="img-fluid d-block" src="img/vv.png" width="50px" />
                <h4 class="text-primary">Agilidade</h4>
                <p>
                  Livre-se daquela fatura em atraso de forma rápida e
                  transparente
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-bind:class="[this.largura < 500] ? 'sec6 p-4' : 'sec6 bg-b-w'"
      :style="
        this.largura < 500
          ? 'background: #09B0F4;'
          : 'background: #09B0F4; margin-bottom: -50px;'
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <h2 class="text-white mb-4">
              Como negociar dívidas no Zero Dívidas?
            </h2>
          </div>
          <div class="d-flex flex-row justify-content-start col-lg-4">
            <div class="card card5 m-0">
              <div class="card-body">
                <img class="img-fluid d-block" src="img/card02.png" />
                <h4 class="text-primary mb-2">Confirme seu CPF</h4>
                <p>Isso é importante para que possamos te identificar</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-start col-lg-4">
            <div class="card card5 m-0">
              <div class="card-body">
                <img class="img-fluid d-block" src="img/card03.png" />
                <h4 class="text-primary mb-2">Escolha sua proposta</h4>
                <p contenteditable="true">
                  Encontre as opções ideais de negociação. Você pode também
                  simular propostas
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-start col-lg-4">
            <div class="card card5 m-0">
              <div class="card-body">
                <img class="img-fluid d-block" src="img/card04.png" />
                <h4 class="text-primary mb-2">Limpe seu nome</h4>
                <p>Gere o boleto e realize seu pagamento</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-bind:class="[this.largura < 500 ? 'sec6 p-2' : 'sec6']"
      style="
        background-image: url('img/Portal-Zero-Dividas-Fundo-1.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <h2 class="mb-4 p-2 text-primary">
              Tire suas dúvidas sobre o Zero Dívida
            </h2>
          </div>
        </div>
        <div class="row" style="">
          <div
            class="col-lg-9"
            v-bind:class="[this.largura < 500 ? 'col-lg-12' : 'col-lg-9']"
          >
            <div id="main1">
              <div class="container" style="margin-top: -22px">
                <div class="accordion" id="faq">
                  <div class="card">
                    <div class="card-header" id="faqhead1">
                      <a
                        href="#"
                        class="btn btn-header-link btn-lg"
                        data-toggle="collapse"
                        data-target="#faq1"
                        aria-expanded="true"
                        aria-controls="faq1"
                        >O Zero Dívida é seguro?</a
                      >
                    </div>
                    <div
                      id="faq1"
                      class="collapse"
                      aria-labelledby="faqhead1"
                      data-parent="#faq"
                      style=""
                    >
                      <div class="card-body">
                        Sim! Somos autorizados a negociar dívidas por todas as
                        marcas parceiras. Além disso, fomos desenvolvidos em
                        parceria com a PGMais, empresa referência no mercado de
                        tecnologia em relacionamento há mais de 12 anos.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead2">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                        >Como o Zero Dívida funciona?
                      </a>
                    </div>
                    <div
                      id="faq2"
                      class="collapse"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        O portal tem parceria com diversas empresas. Dessa
                        forma, você consegue renegociar todas as suas dívidas,
                        com as nossas empresas parceiras, num único lugar e com
                        ofertas exclusivas.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead3">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                        >Após realizar um acordo, quanto tempo leva para meu
                        pagamento ser reconhecido?</a
                      >
                    </div>
                    <div
                      id="faq3"
                      class="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                      style=""
                    >
                      <div class="card-body">
                        <b>Aproximadamente 10 dias úteis.</b> No Zero Dívida, a
                        confirmação do seu pagamento passa também pela checagem
                        de nossas empresas parceiras.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead4">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                        >Fiz o pagamento, mas a dívida ainda aparece no meu CPF,
                        o que devo fazer?
                      </a>
                    </div>
                    <div
                      id="faq4"
                      class="collapse"
                      aria-labelledby="faqhead4"
                      data-parent="#faq"
                      style=""
                    >
                      <div class="card-body">
                        Se você pagou o seu boleto dentro da data de vencimento,
                        guarde seu comprovante. Se após 10 dias úteis, o seu
                        pagamento não foi compensado, basta entrar em contato
                        com a nossa equipe: <b>contato@zerodivida.com.br</b> Nós
                        vamos verificar o que aconteceu!
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead5">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                        >Percebi uma dívida que não reconheço, o que devo fazer?
                      </a>
                    </div>
                    <div
                      id="faq5"
                      class="collapse"
                      aria-labelledby="faqhead5"
                      data-parent="#faq"
                      style=""
                    >
                      <div class="card-body">
                        Se você encontrou alguma dívida que não reconhece no
                        Zero Dívida, envie um email para
                        <b>contato@zerodivida.com.br</b> nós vamos analisar o
                        seu caso.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3" v-if="this.largura > 500">
            <h3 class="mb-4 text-secondary">Ainda ficou com alguma dúvida?</h3>
            <a
              class="btn btn-lg home-form quicks py-4 px-4 btn-block btn-secondary my-3 mx-auto"
              href="mailto:contato@zerodivida.com.br?subject=Duvidas Portal Zero Dividas Online"
              >Fale com a gente<br
            /></a>
          </div>
        </div>
      </div>
      <div
        class="container p-3"
        style="margin-top: 250px"
        v-if="this.largura < 500"
      >
        <h3 class="mb-4 text-secondary">Ainda ficou com alguma dúvida?</h3>
        <a
          class="btn btn-primary btn-lg home-form quicks py-4 px-4 my-2 mx-auto btn-block"
          href="#"
          >Mais dúvidas frequentes<br /></a
        ><a
          class="btn btn-lg home-form quicks py-4 px-4 btn-block btn-secondary my-3 mx-auto"
          href="mailto:contato@zerodivida.com.br?subject=Duvidas Portal Zero Dividas Online"
          >Fale com a gente<br
        /></a>
      </div>
    </div>
  </body>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import { validaCpfCnpj } from "@/utils/helpers";
import ProgressLoading from "./ProgressLoading.vue";
import { encryptTextWithKey, decryptHashWithKey } from "@/utils/crypto";
import { ActionGetIPAddress } from "@/utils/helpers";

// valida cpf e cnpj
const regexaccessdoc = helpers.regex(
  "regexaccessdoc",
  /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/
);

export default {
  components: {
    // AlertMessage,
    ProgressLoading,
  },
  mixins: [validationMixin],
  directives: { mask },
  validations: {
    form: {
      accessdoc: { required, minLength: minLength(14), regexaccessdoc },
    },
  },
  data: () => ({
    form: {
      accessdoc: null,
      accesscode: null,
    },
    error: {
      alert: false,
      status: null,
      message: null,
    },
    errors: false,
    loading: false,
    promoCode: null,
    sendCode: false,
    EmailOrPhone: "email",
    email: true,
    sms: false,
    code: false,
    emailDebtor: null,
    smsDebtor: null,
    infoDebtor: null,
    user: null,
    largura: null,
  }),
  computed: {
    classMessage: function () {
      return {
        "text-danger": this.error.status == "ERROR",
        "text-muted": !this.error.status == "ERROR",
      };
    },
    accessdocErrors() {
      const errors = [];
      if (!this.$v.form.accessdoc.$dirty) return errors;
      !this.$v.form.accessdoc.minLength &&
        errors.push("Digite um CPF/CNPJ válido");
      !this.$v.form.accessdoc.required &&
        errors.push("CPF/CNPJ é obrigatório!");

      if (this.$v.form.accessdoc.length === 14) {
        !this.$v.form.accessdoc.regexaccessdoc &&
          errors.push("CPF não é válido");
      } else if (this.$v.form.accessdoc.length === 18) {
        !this.$v.form.accessdoc.regexaccessdoc &&
          errors.push("CNPJ não é válido");
      }

      return errors;
    },
  },
  methods: {
    ...mapActions("auth", [
      "ActionDoLogin",
      "ActionDoRegisterSession",
      "ActionSetUser",
      "ActionGetContactsByDocNumber",
      "ActionSendPromoCode",
      "ActionGetIPAddress",
      "ActionSetCurrentForm",
      "ActionSetContacts",
    ]),

    invalidateForm() {
      this.errors = true;
    },
    async getContacts() {
      //eslint-disable-next-line
      // debugger;

      this.loading = true;
      this.accessdocErrors.pop();
      this.$v.$touch();
      var isDocValid = validaCpfCnpj(this.form.accessdoc);

      if (this.$v.$invalid || !isDocValid) {
        this.loading = false;
        if (!isDocValid) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: "Informe um CPF/CNPJ válido",
          });
        }
      } else {
        let accessdoc = this.form.accessdoc.replace(/\D+/g, "");

        try {
          let response = await this.ActionGetContactsByDocNumber({
            DocNumber: accessdoc,
          });
          // se não existir sourceinfo chama criar debtor
          if (response.data.results.SourceInfo == "EXTERNO") {
            this.ActionSetContacts({
              DocNumber: response.data.results.DocNumber,
              Emails: response.data.results.Emails,
              PhoneNumbers: response.data.results.MobileNumbers,
              ListEmailsWithMask: response.data.results.ListEmailsWithMask,
              ListPhonesWithMask: response.data.results.ListPhonesWithMask,
              SourceInfo: "API_EXTERNA",
            });
            this.loading = false;
            this.ActionSetCurrentForm("form-send-code"); // th
          } else {
            this.loading = false;
            this.ActionSetContacts(response.data.results);
            this.ActionSetCurrentForm("form-promo-code"); // this.login(response.data.results.DebtorID);
          }
        } catch (error) {
          this.accessdocErrors.push("Não foi possível fazer login!");
          this.error.status = "ERROR";
          this.loading = false;
        }
      }
    },
    sendCodeForm() {
      this.ActionSetCurrentForm("form-send-code");
    },
    clear() {
      this.$v.$reset();
      this.form.accessdoc = "";
      this.form.termos = false;
    },
    encryptData(doc) {
            const encryptedData = encryptTextWithKey(doc, "chave");
            return encryptedData;
        },
    decryptData(value, key) {
          const decryptedData = decryptHashWithKey(value, key);
          return decryptedData.trim();
      },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          if (this.ipUser !== null) {
            console.log("entrou");
            localStorage.setItem("ipUser", this.ipUser);
          }
          console.log("ipUser: " + JSON.stringify(this.ipUser));
          break;
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },
  },
  async created() {
    await this.getIPAddressUser();
    this.largura = window.screen.width;
    if (this.$route.query.cod) {
      var infoEncrypted = this.$route.query.cod;
      if (infoEncrypted !== null) {
        localStorage.setItem("infoEncrypted", infoEncrypted);
        localStorage.removeItem("encryptedData");
        this.ActionSetCurrentForm("fake-loading");
      } else {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Ocorreu um erro.",
        });
        return;
      }
    }
  },
};
</script>

<style scoped>
@media (max-width: 500px) {
  h1 {
    padding-left: 15px;
  }
  .bg-primary {
    padding: 20px;
  }
  .col-lg-9 {
    max-height: 350px;
  }
  h3 {
    font-size: 24pt;
  }
}
</style>
